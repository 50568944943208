import React from "react";
import logo from "../../../assets/icons/logo.png";

const Footer = () => {
  return (
    <>
      <footer className="it-footer-custom">
        <div
          className="container"
          style={{
            margin: "0 auto",
            maxWidth: "1440px",
            padding: "0 30px",
          }}
        >
          <div className="it-footer-main-custom">
            <div className="custom-col">
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <img
                  className={"mr-4"}
                  aria-hidden={"true"}
                  src={logo}
                  alt={"logo"}
                />
                <h4 className="no_toc">Comune di Treviso</h4>
              </div>
              <p className="no-padding no-margin">
                Via Del Municipio, 16 - 31100 Treviso
              </p>
              <p className="no-padding no-margin">TEL: 0422 6581 658201</p>
              <p className="no-padding no-margin">FAX: 0422 658201</p>
            </div>
            <div className="custom-col">
              <ul>
                <li>
                  <p className="no-padding no-margin">
                    Ufficio Relazioni Pubblico:{" "}
                    <a href="mailto:urp@comune.treviso.it">
                      urp@comune.treviso.it
                    </a>{" "}
                  </p>
                </li>
                <li>
                  <p className="no-padding no-margin">
                    Protocollo:{" "}
                    <a href="mailto:protocollo@comune.treviso.it">
                      protocollo@comune.treviso.it
                    </a>{" "}
                  </p>
                </li>
                <li>
                  <p className="no-padding no-margin">
                    PEC:{" "}
                    <a href="mailto:postacertificata@cert.comune.treviso.it">
                      postacertificata@cert.comune.treviso.it
                    </a>
                  </p>
                </li>
              </ul>
            </div>
            <div className="custom-col"></div>
          </div>
        </div>
      </footer>
      <footer className="it-sub-footer-custom">
        <div
          className="container"
          style={{
            margin: "0 auto",
            maxWidth: "1440px",
            padding: "0 30px",
          }}
        >
          <div
            className="it-footer-main-custom"
            style={{
              borderTop: "1px solid #fff",
            }}
          >
            <ul
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
                flexWrap: "wrap",
                marginLeft: "0 !important",
              }}
            >
              <li className="sub-footer-li">
                <a
                  target="_blank"
                  referrerPolicy="no-referrer"
                  href="https://www.comune.treviso.it/"
                >
                  Homepage
                </a>
              </li>
              <li className="sub-footer-li">
                <a target="_blank" referrerPolicy="no-referrer" href="#">
                  Dichiarazione di accessibilità
                </a>
              </li>
              <li className="sub-footer-li">
                <a
                  target="_blank"
                  referrerPolicy="no-referrer"
                  href="https://www.comune.treviso.it/myportal/C_L407/amministrazione-info/informativa-prenotazioni"
                >
                  Privacy
                </a>
              </li>
              <li className="sub-footer-li">
                <a
                  target="_blank"
                  referrerPolicy="no-referrer"
                  href=" https://www.comune.treviso.it/myportal/C_L407/amministrazione-info/informativa-prenotazioni"
                >
                  Informativa Cookie
                </a>
              </li>
            </ul>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
