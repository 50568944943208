import React, { useEffect, useMemo, useState } from "react";
import axios from "axios";
import {
  startOfMonth,
  endOfMonth,
  eachDayOfInterval,
  getDay,
  format,
  set,
} from "date-fns";
import calendarPrev from "../../../assets/icons/calendar-prev.svg";
import calendarNext from "../../../assets/icons/calendar-next.svg";
import { it } from "date-fns/locale";
import { BASE_URL } from "../../../server";
import getHeaders from "../../../utils/handleHeaderFunc";

const Calendar = ({
  savedBlockId,
  setSavedBlockId,
  selectedSlots,
  setSelectedSlots,
}) => {
  const [month, setMonth] = useState(
    sessionStorage.getItem("selectedDate")?.slice(5, 7) ||
      new Date().getMonth() + 1
  );
  const [year, setYear] = useState(
    sessionStorage.getItem("selectedDate")?.slice(0, 4) ||
      new Date().getFullYear()
  );
  const [slots, setSlots] = useState([]);
  /*   const savedService = sessionStorage.getItem("selectedService"); */
  const officeServiceId = sessionStorage.getItem("officeServiceId");
  const [selectedDate, setSelectedDate] = useState(
    sessionStorage.getItem("selectedDate") || ""
  );

  const [selectedBlockId, setSelectedBlockId] = useState(savedBlockId || "");
  const serviceData = JSON.parse(sessionStorage.getItem("serviceData"));

  // fetch slots for month
  const fetchSlotsForMonth = async (year, month) => {
    const headers = getHeaders();
    try {
      const response = await axios.get(
        `${BASE_URL}/free_slots_month/${year}-${month}/${officeServiceId}`,
        {
          headers: headers,
        }
      );
      return response.data.freeBlocks;
    } catch (error) {
      console.error(error);
      return [];
    }
  };

  // find first available month
  useEffect(() => {
    const findFirstAvailableMonth = async () => {
      let currentYear = year;
      let currentMonth = month;

      let slots = await fetchSlotsForMonth(currentYear, currentMonth);

      while (slots.length === 0 && currentMonth < 12) {
        currentMonth++;
        slots = await fetchSlotsForMonth(currentYear, currentMonth);
      }

      if (slots.length === 0) {
        currentMonth = 1;
        currentYear++;
        slots = await fetchSlotsForMonth(currentYear, currentMonth);
      }

      setMonth(currentMonth);
      setYear(currentYear);
      setSlots([slots]);
    };

    findFirstAvailableMonth();
  }, []);

  useEffect(() => {
    const headers = getHeaders();
    const fetchSlots = async () => {
      try {
        // aggiungere header per autenticazione
        const response = await axios.get(
          `${BASE_URL}/free_slots_month/2023-${month}/${officeServiceId}`,
          {
            headers: headers,
          }
        );
        setSlots(response.data.freeBlocks);
      } catch (error) {
        console.error(error);
      }
    };

    fetchSlots();
  }, [year, month, officeServiceId]);

  // check booking availability

  useEffect(() => {
    const headers = getHeaders();
    if (selectedBlockId) {
      const fetchAvailability = async () => {
        try {
          // API con proxy
          /*   const availability = await axios.post(
            "http://localhost:3001/api/check_booking_availability",
            {
              office_service_id: officeServiceId,
              block_id: selectedBlockId,
              client_id: JSON.parse(sessionStorage.getItem("user")).id,
              // PROXY SPECIFIC
              method: "POST",
              endpoint: "check_booking_availability",
            }
          ); */
          // API senza proxy
          const availability = await axios.post(
            `${BASE_URL}/check_booking_availability`,

            {
              office_service_id: officeServiceId,
              block_id: selectedBlockId,
              client_id: JSON.parse(sessionStorage.getItem("user")).id,
            },
            {
              headers: headers,
            }
          );
        } catch (error) {
          console.error(error);
        }
      };
      fetchAvailability();
    }
  }, [selectedBlockId]);

  const nextMonth = () => {
    console.log("month", month);
    console.log("year", year);
    if (parseInt(month) === 12) {
      setMonth(1);
      setYear(parseInt(year) + 1);
    } else {
      setMonth(parseInt(month) + 1);
    }
  };

  const prevMonth = () => {
    if (parseInt(month) === 1) {
      setMonth(12);
      setYear(parseInt(year) - 1);
    } else {
      setMonth(parseInt(month) - 1);
    }
  };

  const hasAvailableSlots = (day) => {
    const formattedDay = day.toString().padStart(2, "0");
    const formattedMonth = month.toString().padStart(2, "0");
    const formattedYear = year.toString();
    const formattedDate = `${formattedYear}-${formattedMonth}-${formattedDay}`;

    if (!Array.isArray(slots) || slots.length === 0) {
      return false;
    } else {
      return slots?.some(
        (block) =>
          block.date?.startsWith(formattedDate) && parseInt(block.is_free) >= 1
      );
    }
  };

  const startDate = startOfMonth(new Date(year, month - 1));
  const endDate = endOfMonth(new Date(year, month - 1));
  const daysOfMonth = eachDayOfInterval({ start: startDate, end: endDate });

  const calendarRows = Math.ceil(daysOfMonth.length / 7);

  const handleDayClick = (day) => {
    const formattedDay = day.toString().padStart(2, "0");
    const formattedMonth = month.toString().padStart(2, "0");
    const formattedYear = year.toString();
    const formattedDate = `${formattedYear}-${formattedMonth}-${formattedDay}`;

    sessionStorage.setItem("selectedDate", formattedDate);
    sessionStorage.removeItem("selectedStartTime");
    sessionStorage.removeItem("selectedEndTime");
    sessionStorage.removeItem("selectedBlockId");

    setSelectedBlockId("");

    if (Array.isArray(slots) && slots.length > 0) {
      const selectedBlocks = slots.filter(
        (block) =>
          block.date?.startsWith(formattedDate) && parseInt(block.is_free) >= 1
      );

      if (selectedBlocks.length > 0) {
        const selectedSlotsForDay = selectedBlocks.map((block) => {
          const startTime = block.date?.slice(11, 16);
          const endTime = calculateEndTime(startTime, day);
          const blockId = block.block_id;

          return {
            startTime,
            endTime,
            blockId,
          };
        });

        setSelectedDate(formattedDate);
        setSelectedSlots(selectedSlotsForDay);
        sessionStorage.setItem(
          "selectedSlots",
          JSON.stringify(selectedSlotsForDay)
        );
      }
    }
  };

  const calculateEndTime = (startTime, day) => {
    const [hours, minutes] = startTime.split(":");
    const startDateTime = new Date(year, month - 1, day, hours, minutes);
    const endDateTime = new Date(
      startDateTime.getTime() + parseInt(serviceData?.duration) * 60000
    );
    const endHours = endDateTime.getHours().toString().padStart(2, "0");
    const endMinutes = endDateTime.getMinutes().toString().padStart(2, "0");

    return `${endHours}:${endMinutes}`;
  };

  const handleTimeClick = (blockId) => {
    const selectedSlot = selectedSlots.find((slot) => slot.blockId === blockId);
    if (selectedSlot) {
      const { startTime, endTime } = selectedSlot;
      sessionStorage.setItem("selectedStartTime", startTime);
      sessionStorage.setItem("selectedEndTime", endTime);
      sessionStorage.setItem("selectedBlockId", blockId);
      setSelectedBlockId(blockId);
      setSavedBlockId(blockId);
    }
  };

  const monthFullName = useMemo(
    () => format(new Date(year, month - 1), "MMMM", { locale: it }),
    [month, year]
  );

  const capitalizedMonth = useMemo(
    () => monthFullName.charAt(0).toUpperCase() + monthFullName.slice(1),
    [monthFullName]
  );

  const dayIsPast = (day) => {
    const formattedDay = day.toString().padStart(2, "0");
    const formattedMonth = month.toString().padStart(2, "0");
    const formattedYear = year.toString();
    const formattedDate = `${formattedYear}-${formattedMonth}-${formattedDay}`;

    const today = new Date();
    const formattedToday = format(today, "yyyy-MM-dd");

    return formattedDate < formattedToday;
  };

  return (
    <div className={"flex flex-wrap bg-[#F7F9FB] md:p-5"}>
      <div className={"w-full lg:w-2/3 pt-2"}>
        <h1>Seleziona una data</h1>
        <div
          className={
            "w-full bg-white border align-content-between justify-between flex px-4 py-2 rounded-xl mb-2"
          }
        >
          <button onClick={prevMonth}>
            <img src={calendarPrev} alt={"Previous Month"} />
          </button>
          <span
            style={{
              fontSize: "1.3rem",
              fontWeight: 600,
            }}
          >{`${capitalizedMonth} ${year}`}</span>
          <button onClick={nextMonth}>
            <img src={calendarNext} alt={"Next Month"} />
          </button>
        </div>

        <div className={"w-full bg-transparent"}>
          <div className={"text-center text-[#153F69] flex mb-2"}>
            <div className={"font-semibold w-full"}>L</div>
            <div className={"font-semibold w-full"}>M</div>
            <div className={"font-semibold w-full"}>M</div>
            <div className={"font-semibold w-full"}>G</div>
            <div className={"font-semibold w-full"}>V</div>
            <div className={"font-semibold w-full"}>S</div>
            <div className={"font-semibold w-full"}>D</div>
          </div>
          {Array(calendarRows)
            .fill(null)
            .map((_, weekIndex) => (
              <div key={weekIndex} className={"flex"}>
                {Array(7)
                  .fill(null)
                  .map((_, dayIndex) => {
                    const day =
                      weekIndex * 7 + dayIndex + 2 - getDay(startDate);
                    const isCurrentMonth =
                      day >= 1 && day <= daysOfMonth.length;

                    let formattedDate = "";
                    if (isCurrentMonth) {
                      const formattedDay = day.toString().padStart(2, "0");
                      const formattedMonth = month.toString().padStart(2, "0");
                      const formattedYear = year.toString();
                      formattedDate = `${formattedYear}-${formattedMonth}-${formattedDay}`;
                    }

                    const slotAvailable =
                      isCurrentMonth && hasAvailableSlots(day);

                    const cellClass = dayIsPast(day)
                      ? "not-allowed"
                      : !isCurrentMonth ||
                        selectedDate === formattedDate ||
                        slotAvailable
                      ? " "
                      : "not-allowed";

                    const cellStyle = !isCurrentMonth
                      ? { backgroundColor: "#ccc" }
                      : dayIsPast(day)
                      ? {
                          backgroundColor: "white",
                          border: "2px solid #ccc",
                          cursor: "not-allowed",
                          opacity: "0.5",
                        }
                      : selectedDate === formattedDate
                      ? {
                          backgroundColor: "#5cb85c",
                          color: "white",
                          border: "2px solid #5cb85c",
                        }
                      : slotAvailable
                      ? {
                          border: "2px solid #5cb85c",
                          backgroundColor: "#5cb85c20",
                          cursor: "pointer",
                        }
                      : /*   : slots?.some(
                          (block) =>
                            block.date?.startsWith(formattedDate) &&
                            block.is_free === "0"
                        )
                      ? { backgroundColor: "#BF0404", color: "white" } */
                      dayIsPast(day)
                      ? {
                          backgroundColor: "white",
                          border: "2px solid #ccc",
                          cursor: "not-allowed",
                          opacity: "0.5",
                        }
                      : {
                          backgroundColor: "white",
                          border: "2px solid #ccc",
                          cursor: "not-allowed",
                          opacity: "0.5",
                        };

                    return (
                      <div
                        key={dayIndex}
                        onClick={() => {
                          if (slotAvailable) {
                            handleDayClick(day);
                          }
                        }}
                        style={cellStyle}
                        className={`w-full rounded-lg py-2 sm:px-2 lg:px-4 m-1 
                 text-center ${cellClass}`}
                      >
                        <span className={"font-semibold"}>
                          {isCurrentMonth ? day : ""}
                        </span>
                      </div>
                    );
                  })}
              </div>
            ))}
        </div>
      </div>
      <div className={"w-full lg:w-1/3 pt-2"}>
        <div className="w-full lg:ml-4 pr-4">
          <h1>Seleziona un orario</h1>
          {selectedDate ? (
            <div>
              <p>Data selezionata: {selectedDate}</p>
              <ul
                className="
                grid gap-2 grid-cols-2 ml-0 p-0 
              "
              >
                {selectedSlots.map((slot) => {
                  return (
                    <SlotBlock
                      key={slot.blockId}
                      slot={slot}
                      handleTimeClick={handleTimeClick}
                      isSelected={slot.blockId === selectedBlockId}
                    />
                  );
                })}
              </ul>
            </div>
          ) : (
            <p>Seleziona una data per visualizzare gli orari disponibili.</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default Calendar;

const SlotBlock = ({ slot, handleTimeClick, isSelected }) => {
  return (
    <li
      key={slot.blockId}
      onClick={() => handleTimeClick(slot.blockId)}
      className={` cursor-pointer border-2 border-[#D2D8DD] rounded-xl text-center  py-2  md:px-4 text-sm font-semibold hover:bg-[#5cb85c] hover:text-white hover:border-[#5cb85c]
       ${
         isSelected &&
         `border-[#5cb85c]
         bg-[#5cb85c] text-white`
       }`}
    >
      {slot.startTime} - {slot.endTime}
    </li>
  );
};
