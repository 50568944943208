import React, { useEffect, useState } from "react";
import "bootstrap-italia/dist/css/bootstrap-italia.min.css";
import axios from "axios";
import { it } from "date-fns/locale";
import { format } from "date-fns";
import icons from "bootstrap-italia/dist/svg/sprites.svg";
import { BASE_URL } from "../../server";
import getHeaders from "../../utils/handleHeaderFunc";

const Appuntamenti = ({ newBookings = [], oldBookings }) => {
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const [deletedAppointment, setDeletedAppointment] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [bookings, setBookings] = useState([]);
  const handleDelete = (appointment) => {
    setDeletedAppointment(appointment);
    setShowDeleteModal(true);
  };

  useEffect(() => {
    if (newBookings.length > 0) {
      setBookings(newBookings);
    }
  }, [newBookings]);

  const confirmDelete = async () => {
    const headers = getHeaders();
    try {
      // API CON PROXY
      /*   const response = await axios.post(
        `http://localhost:3001/api/delete_booking/${deletedAppointment.booking_id}`,
        {
          // PROXY SPECIFIC
          method: "DELETE",
          endpoint: `delete_booking/${deletedAppointment.booking_id}`,
        }
      ); */

      // API SENZA PROXY
      const response = await axios.delete(
        `${BASE_URL}/delete_booking/${deletedAppointment.booking_id}`,
        {
          headers: headers,
        }
      );

      if (!!response.data.code && response.data.code === 200) {
        // chiusura del modal
        setDeletedAppointment(null);
        // Aggiorna la lista degli appuntamenti
        const newBookings = bookings.filter(
          (booking) => booking.booking_id !== deletedAppointment.booking_id
        );
        setBookings(newBookings);
        // mostro l'alert di successo
        setShowSuccessAlert(true);
        setTimeout(() => setShowSuccessAlert(false), 3000);
      } else {
        console.error("Errore nella cancellazione dell'appuntamento");
        setShowErrorAlert(true);
        setTimeout(() => setShowErrorAlert(false), 3000);
      }
    } catch (error) {
      console.error("Errore nella chiamata API", error);
      setShowErrorAlert(true);
      setTimeout(() => setShowErrorAlert(false), 3000);
    }

    setShowDeleteModal(false);
  };

  return (
    <div
      style={{
        minHeight: "90vh",
      }}
    >
      <div className="d-flex align-items-center mt-4">
        <svg
          // onCLick redirect to homepage!!
          onClick={() => (window.location.href = "/")}
          className="icon icon-primary icon-sm mr-2 cursor-pointer "
        >
          <use href={icons + "#it-arrow-left"}></use>
        </svg>

        <h3 className="text-primary mb-0">Gestione Appuntamenti</h3>
      </div>
      {showSuccessAlert && (
        <div className="alert alert-success mt-4" role="alert">
          Appuntamento eliminato con successo.
        </div>
      )}
      {showErrorAlert && (
        <div className="alert alert-danger mt-4" role="alert">
          Errore nella cancellazione dell'appuntamento.
        </div>
      )}
      <div className="row mt-4">
        <h4 className="col-12 mb-4 text-gray-600">Lista Prenotazioni</h4>
        {bookings.length > 0 ? (
          bookings.map((booking) => (
            <div key={booking.booking_id} className="col-md-4 mb-4">
              {/*       <div className="card shadow bg-white rounded"> */}
              <div className="p-4 shadow bg-white rounded">
                <div className="card-body">
                  <h5 className=" text-primary text-xl font-semibold">
                    {`${booking.name} - ${booking.service_name}`}
                  </h5>
                  <p className="text-md">
                    <p className="capitalize font-semibold text-gray-600">{`${format(
                      new Date(booking.date),
                      "EEEE d MMMM yyyy",
                      {
                        locale: it,
                      }
                    )} alle ore ${format(new Date(booking.date), "HH:mm", {
                      locale: it,
                    })}`}</p>
                  </p>
                  {/*     <p className="card-text">
                    Descrizione: {booking.description}
                  </p> */}
                  <div className="d-flex justify-content-end">
                    <button
                      className="btn btn-danger btn-sm "
                      onClick={() => handleDelete(booking)}
                    >
                      Elimina
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ))
        ) : (
          <p className="col-12 text-gray-400">
            Al momento non hai nessun appuntamento attivo
          </p>
        )}
      </div>
      {showDeleteModal && (
        <>
          <div className="fixed inset-0 bg-black opacity-50 z-50"></div>
          <div
            className="modal show"
            id="deleteModal"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="deleteModalLabel"
          >
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <button
                  type="button"
                  className="close absolute right-3 top-0"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={() => setShowDeleteModal(false)}
                >
                  <span
                    aria-hidden="true
                  "
                    className="
                     text-2xl font-semibold"
                  >
                    &times;
                  </span>
                </button>
                <div className="modal-header">
                  <h5 className="modal-title" id="deleteModalLabel">
                    Conferma Eliminazione
                  </h5>
                </div>
                <div className="modal-body">
                  <p>Sei sicuro di voler eliminare l'appuntamento?</p>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    data-dismiss="modal"
                    onClick={() => setShowDeleteModal(false)}
                  >
                    Annulla
                  </button>
                  <button
                    type="button"
                    className="btn btn-danger"
                    onClick={confirmDelete}
                  >
                    Elimina
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Appuntamenti;

{
  // SEZIONE APPUNTAMENTI VECCHI
  /*       <div className="row mt-4">
        <h4 className="col-12">Appuntamenti Vecchi</h4>
        {oldBookings.length > 0 ? (
          oldBookings.map((booking) => (
            <div key={booking.id} className="col-md-4 mb-4">
              <div className="card shadow bg-white rounded">
                <div className="card-body">
                  <h5 className="card-title">{booking.office_name}</h5>
                  <p className="card-text">
                    Data: {formatDateTime(booking.date)}
                  </p>
                  <p className="card-text">
                    Descrizione: {booking.description}
                  </p>
                </div>
              </div>
            </div>
          ))
        ) : (
          <p className="col-12">Nessun appuntamento vecchio</p>
        )}
      </div>
 */
}
