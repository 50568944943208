import "./App.css";
/* import {HeaderSticky} from "./frontend/components/header/Header";
import {Navigate, Route, Routes} from "react-router-dom";
import Servizio from "./frontend/pages/Servizio";
import LoginForm from "./backend/pages/LoginForm"; */
import { useEffect, useState } from "react";
/* import Dashboard from "./backend/pages/Dashboard";
import {Homepage} from "./frontend/pages/Homepage"; */
import Guest from "./backend/pages/Guest";
import Auth from "./backend/pages/Auth";
import AuthUser from "./backend/pages/AuthUser";
import { useLocation } from "react-router-dom";

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const location = useLocation();

  // scroll to top on route change

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      setIsLoggedIn(true);
    }
  }, []);

  const handleLogin = (token) => {
    setIsLoggedIn(true);
    localStorage.setItem("token", token);
  };

  const handleLogout = () => {
    setIsLoggedIn(false);
    localStorage.removeItem("token");
  };

  const { getToken } = AuthUser();
  if (!getToken()) {
    return <Guest />;
  }
  return <Auth />;
}

export default App;
